import { render, staticRenderFns } from "./classTop.vue?vue&type=template&id=641a4a61&scoped=true&"
import script from "./classTop.vue?vue&type=script&lang=js&"
export * from "./classTop.vue?vue&type=script&lang=js&"
import style0 from "./classTop.vue?vue&type=style&index=0&id=641a4a61&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641a4a61",
  null
  
)

export default component.exports