<template>
	<div class="topclass">
		<div class="p-top-body">
			<div class="p-top-left">
				<div :class="['p-top-left-item', choose==index ? 'p-top-left-item p-top-left-item-c':'']" v-for="(item,index) in data.cat"
				 :key="index" @click="whichchoose(index,item.id)" >
					<div>{{item.cat_name}}</div> <img class="p-mrLeft" v-if="choose==index" src="../../../assets/img/home/mrleft.png" />
				</div>
			</div>
			<div class="p-top-right">
				<div class="p-top-head">
					<img src="../../../assets/img/home/shuaxuan.png" />
					<div>分类筛选</div>
					<div :class="['p-top-item', chooseaa=='lfs' ? 'p-top-item p-top-item-c':'']" @click="whichchoose(choose,cat_id)">
						<div>全部</div>
					</div>
					<div :class="['p-top-item', chooseaa==indexaa ? 'p-top-item p-top-item-c':'']" v-for="(item,indexaa) in data.cat[choose].child"
					 :key="indexaa" @click="whichchooseaa(indexaa,item.id)">
						<div>{{item.cat_name}}</div>
					</div>
				</div>
				<div class="p-top-right-body">
					<div class="p-right-body-item" v-for="(item,index) in data.goodslist" :key="index" @click="tomicrosft(item.micro_gid)">
						<img :src="item.goods_img" />
						<div>{{item.goods_name}}</div>
						<!-- 						<div class="classtopsize">这里是微专业简要描述。</div> -->
					</div>
				</div>
<!-- 				<div class="pagination">
					<el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" layout="prev, pager, next"
					 :total="total"></el-pagination>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '逸教云课堂', // set a title
			meta: [{ // set meta
					name: '逸教云课堂',
					content: '新一代智能教育，让你的孩子与众不同'
				},
				{
					name: '智能教育',
					content: '想学习到逸教云课堂'
				}
			],
			link: [{ // set link
				rel: 'asstes',
				href: 'https://ykt.qdy2020.com/'
			}]
		},
		data() {
			return {
				choose: 0,
				chooseaa: 'lfs',
				cat_id: 0,
				data: [],
				// 分页
				list_rows: 1,
				page: 1,
				currentPage: 1,
				total: 0,
			}
		},
		watch: {
			'$route': function() {
				this.getdata(0)
			}
		},
		created() {
			this.getdata(0)
		},
		methods: {
			whichchoose(e, id) {
				this.choose = e
				this.cat_id = id
				this.chooseaa = 'lfs'
				this.getdata(this.data.cat[this.choose].id)
			},
			//微专业
			tomicrosft(e) {
				this.$router.push({
					path: '/microcoursedetail',
					query: {
						id: e
					}
				})
			},
			// 当前页的变化
			// handleCurrentChange(e) {
			// 	this.page = e
			// 	this.getdata(this.data.cat[this.choose].id)
			// },
			whichchooseaa(e, id) {
				this.chooseaa = e
				this.getdata(id)
			},
			getdata(e) {
				scroll(0, 0)
				this.$get('index/microcatgoods/', {
					cat_id: e,
					size: 5
				}).then(response => {
					if (response.error == 0) {
						this.data = response
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			goBack() {

			},
		}
	}
</script>

<style scoped>
	.topclass {
		width: 80%;
		margin-left: 10%;
	}

	.classtopsize {
		color: #999999;
		font-size: 12px;
	}

/* 	.pagination {
		width: 100%;
		height: auto;
		text-align: center;
	} */
</style>
